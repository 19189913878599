import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../middleware/securityAccess'
// import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/ventas',
    component: Layouts,
    name: 'pharmasan',
    redirect: { name: 'pharmasan.ventas.ov' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'ov',
        name: 'pharmasan.ventas.ov',
        component: () => import('../views/ordenes/index.vue'),
        redirect: { name: 'pharmasan.ventas.ov.listado' },
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'pharmasan.ventas.ov'
          ]
        },
        children: [
          {
            path: 'crear',
            name: 'pharmasan.ventas.ov.crear',
            component: () => import('../views/ordenes/crear/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.crear'
              ]
            }
          },
          // PERMISO REEMPLAZAR MD EN EL EDITAR ---> pharmasan.ventas.ov.editar.reemplazar-md
          // PERMISO CREAR O EDITAR OV CON FECHAS FUTURAS DE CONTABILIDAD ---> pharmasan.ventas.ov.config_global.fecha_contabilidad
          {
            path: 'editar/:id?',
            name: 'pharmasan.ventas.ov.editar',
            component: () => import('../views/ordenes/editar/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.editar'
              ]
            }
          },
          {
            path: 'listado',
            name: 'pharmasan.ventas.ov.listado',
            component: () => import('../views/ordenes/listado'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.listado'
              ]
            }
          },
          {
            path: 'ver/:id?',
            name: 'pharmasan.ventas.ov.ver',
            component: () => import('../views/ordenes/ver/index.vue'),
            beforeEnter: [securityAccess],
            redirect: { name: 'pharmasan.ventas.ov.ver.tabla' },
            meta: {
              access: [
                'pharmasan.ventas.ov.ver'
              ],
              activeMenu: 'pharmasan.ventas.ov.listado'
            },
            children: [
              {
                path: 'tabla',
                name: 'pharmasan.ventas.ov.ver.tabla',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/tabla.vue'),
                meta: {
                  access: [
                    'pharmasan.ventas.ov.ver'
                  ],
                  activeMenu: 'pharmasan.ventas.ov.listado'
                }
              },
              {
                path: 'mapa-relaciones',
                name: 'pharmasan.ventas.ov.ver.mapa-relaciones',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/mapa-relaciones.vue'),
                meta: {
                  access: [
                    'pharmasan.ventas.ov.ver'
                  ],
                  activeMenu: 'pharmasan.ventas.ov.listado'
                }
              },
              {
                path: 'historial',
                name: 'pharmasan.ventas.ov.ver.historial',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/historial.vue'),
                meta: {
                  access: [
                    'pharmasan.ventas.ov.ver'
                  ],
                  activeMenu: 'pharmasan.ventas.ov.listado'
                }
              },
              {
                path: 'devolucion',
                name: 'pharmasan.ventas.ov.ver.devolucion',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/devolucion.vue'),
                meta: {
                  access: [
                    'pharmasan.ventas.ov.devoluciones'
                  ],
                  activeMenu: 'pharmasan.ventas.ov.listado'
                }
              }
            ]
          },
          {
            path: 'sync-entrega-masiva',
            name: 'pharmasan.ventas.ov.sync-entrega-masiva',
            component: () => import('../views/ordenes/syncEntregasMasivas/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.sync-entrega-masiva'
              ]
            }
          },
          {
            path: 'sync-entrega-masiva/historial',
            name: 'pharmasan.ventas.ov.sync-entrega-masiva.historial',
            component: () => import('../views/ordenes/syncEntregasMasivas/historial.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.sync-entrega-masiva.historial'
              ],
              activeMenu: 'pharmasan.ventas.ov.sync-entrega-masiva'
            }
          },
          {
            path: 'pagos-recibidos',
            name: 'pharmasan.ventas.ov.pagos-recibidos',
            component: () => import('../views/ordenes/pagosRecibidos'),
            redirect: { name: 'pharmasan.ventas.ov.pagos-recibidos.listado' },
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.pagos-recibidos'
              ]
            },
            children: [
              {
                path: '',
                name: 'pharmasan.ventas.ov.pagos-recibidos.listado',
                component: () => import('../views/ordenes/pagosRecibidos/pages/listado.vue'),
                beforeEnter: [securityAccess],
                meta: {
                  access: [
                    'pharmasan.ventas.ov.pagos-recibidos'
                  ],
                  activeMenu: 'pharmasan.ventas.ov.pagos-recibidos'
                }
              },
              {
                path: 'historial',
                name: 'pharmasan.ventas.ov.pagos-recibidos.historial',
                component: () => import('../views/ordenes/pagosRecibidos/pages/historial.vue'),
                beforeEnter: [securityAccess],
                meta: {
                  access: [
                    'pharmasan.ventas.ov.pagos-recibidos.historial'
                  ],
                  activeMenu: 'pharmasan.ventas.ov.pagos-recibidos'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'configuracion',
        name: 'pharmasan.ventas.configuracion',
        component: () => import('../views/ordenes/configuracion/index.vue'),
        redirect: { name: 'pharmasan.ventas.configuracion.home' },
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'pharmasan.ventas.configuracion'
          ]
        },
        children: [
          {
            path: '',
            name: 'pharmasan.ventas.configuracion.home',
            component: () => import('../views/ordenes/configuracion/views/home.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'tabla-ov',
            name: 'pharmasan.ventas.configuracion.tabla-ov',
            component: () => import('../views/ordenes/configuracion/views/tabla_crear_ov.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.tabla-ov'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'bodegas-usuarios',
            name: 'pharmasan.ventas.configuracion.bodegas-usuarios',
            component: () => import('../views/ordenes/configuracion/views/bodegas_usuarios.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.bodegas-usuarios'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'articulos',
            name: 'pharmasan.ventas.configuracion.articulos',
            component: () => import('../views/ordenes/configuracion/articulos/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.articulos'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'articulos/crear',
            name: 'pharmasan.ventas.configuracion.articulos.crear',
            component: () => import('../views/ordenes/configuracion/articulos/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.articulos.crear'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'articulos/editar/:id',
            name: 'pharmasan.ventas.configuracion.articulos.editar',
            component: () => import('../views/ordenes/configuracion/articulos/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.articulos.editar'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'bodegas',
            name: 'pharmasan.ventas.configuracion.bodegas',
            component: () => import('../views/ordenes/configuracion/bodegas/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.bodegas'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'bodegas/crear',
            name: 'pharmasan.ventas.configuracion.bodegas.crear',
            component: () => import('../views/ordenes/configuracion/bodegas/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.bodegas.crear'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'bodegas/editar/:id',
            name: 'pharmasan.ventas.configuracion.bodegas.editar',
            component: () => import('../views/ordenes/configuracion/bodegas/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.bodegas.editar'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'modalidad-contrato',
            name: 'pharmasan.ventas.configuracion.modalidad-contrato',
            component: () => import('../views/ordenes/configuracion/modalidad-contrato/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.modalidad-contrato'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'modalidad-contrato/crear',
            name: 'pharmasan.ventas.configuracion.modalidad-contrato.crear',
            component: () => import('../views/ordenes/configuracion/modalidad-contrato/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.modalidad-contrato.crear'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'modalidad-contrato/editar/:id',
            name: 'pharmasan.ventas.configuracion.modalidad-contrato.editar',
            component: () => import('../views/ordenes/configuracion/modalidad-contrato/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.modalidad-contrato.editar'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'motivo-autorizacion',
            name: 'pharmasan.ventas.configuracion.motivo-autorizacion',
            component: () => import('../views/ordenes/configuracion/motivo-autorizacion/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.motivo-autorizacion'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'ov-cliente',
            name: 'pharmasan.ventas.configuracion.ov-cliente',
            component: () => import('../views/ordenes/configuracion/ov-cliente/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.ov-cliente'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'pacientes',
            name: 'pharmasan.ventas.configuracion.pacientes',
            component: () => import('../views/ordenes/configuracion/pacientes/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.pacientes'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'pacientes/crear',
            name: 'pharmasan.ventas.configuracion.pacientes.crear',
            component: () => import('../views/ordenes/configuracion/pacientes/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.pacientes.crear'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'pacientes/editar/:id',
            name: 'pharmasan.ventas.configuracion.pacientes.editar',
            component: () => import('../views/ordenes/configuracion/pacientes/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.pacientes.editar'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'ips',
            name: 'pharmasan.ventas.configuracion.ips',
            component: () => import('../views/ordenes/configuracion/ips/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.ips'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'localizacion',
            name: 'pharmasan.ventas.configuracion.localizacion',
            component: () => import('../views/ordenes/configuracion/localizacion/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.localizacion'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'medicos',
            name: 'pharmasan.ventas.configuracion.medicos',
            component: () => import('../views/ordenes/configuracion/medicos/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.medicos'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'diagnosticos',
            name: 'pharmasan.ventas.configuracion.diagnosticos',
            component: () => import('../views/ordenes/configuracion/diagnosticos/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.diagnosticos'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'frecuencias',
            name: 'pharmasan.ventas.configuracion.frecuencias',
            component: () => import('../views/ordenes/configuracion/frecuencias/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.frecuencias'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'duracion-tratamiento',
            name: 'pharmasan.ventas.configuracion.duracion-tratamiento',
            component: () => import('../views/ordenes/configuracion/duracion-tratamiento/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.duracion-tratamiento'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'seguimiento-entregas',
            name: 'pharmasan.ventas.configuracion.seguimiento-entregas',
            component: () => import('../views/ordenes/configuracion/seguimiento-entregas/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.seguimiento-entregas'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'regimen',
            name: 'pharmasan.ventas.configuracion.regimen',
            component: () => import('../views/ordenes/configuracion/regimen/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.regimen'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'calculo-cuota',
            name: 'pharmasan.ventas.configuracion.calculo-cuota',
            component: () => import('../views/ordenes/configuracion/calculo-cuota/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.calculo-cuota'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'config-sap',
            name: 'pharmasan.ventas.configuracion.config-sap',
            component: () => import('../views/ordenes/configuracion/config-sap/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.calculo-cuota'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            }
          },
          {
            path: 'config-mipres',
            name: 'pharmasan.ventas.configuracion.config-mipres',
            redirect: { name: 'pharmasan.ventas.configuracion.config-mipres.dias-previos' },
            component: () => import('../views/ordenes/configuracion/mipres/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.configuracion.config-mipres'
              ],
              activeMenu: 'pharmasan.ventas.configuracion'
            },
            children: [
              {
                path: 'dias-previos',
                name: 'pharmasan.ventas.configuracion.config-mipres.dias-previos',
                component: () => import('../views/ordenes/configuracion/mipres/components/configuracion_ov.vue'),
                beforeEnter: [securityAccess],
                meta: {
                  access: [
                    'pharmasan.ventas.configuracion.config-mipres.dias-previos'
                  ],
                  activeMenu: 'pharmasan.ventas.configuracion'
                }
              },
              {
                path: 'cargue-archivos',
                name: 'pharmasan.ventas.configuracion.config-mipres.cargue-archivos',
                component: () => import('../views/ordenes/configuracion/mipres/components/cargue_documentos.vue'),
                beforeEnter: [securityAccess],
                meta: {
                  access: [
                    'pharmasan.ventas.configuracion.config-mipres.cargue-archivos'
                  ],
                  activeMenu: 'pharmasan.ventas.configuracion'
                }
              }
            ]
          },
          {
            path: 'apartados',
            name: 'pharmasan.ventas.ov.apartados',
            component: () => import('../views/ordenes/apartados/listado.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.ventas.ov.apartados'
              ],
              activeMenu: 'pharmasan.ventas.ov.apartados'
            }
          }
        ]
      },
      {
        path: 'reportes',
        name: 'pharmasan.ventas.reportes',
        component: () => import('../views/reportes/ReportesMain.vue'),
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'pharmasan.ventas.reportes'
          ]
        }
      },
      {
        path: 'contrato-precio',
        name: 'pharmasan.ventas.contrato-precio',
        component: () => import('../views/contratos/index.vue'),
        redirect: { name: 'pharmasan.ventas.contrato-precio.listado' },
        beforeEnter: [securityAccess],
        meta: {
          access: ['pharmasan.ventas.contrato-precio']
        },
        children: [
          {
            path: '',
            name: 'pharmasan.ventas.contrato-precio.listado',
            component: () => import('../views/contratos/pages/listado.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.ventas.contrato-precio'],
              activeMenu: 'pharmasan.ventas.contrato-precio.listado'
            }
          },
          {
            path: 'crear',
            name: 'pharmasan.ventas.contrato-precio.crear',
            component: () => import('../views/contratos/pages/crear/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.ventas.contrato-precio.crear'],
              activeMenu: 'pharmasan.ventas.contrato-precio.listado'
            }
          },
          {
            path: 'editar/:id',
            name: 'pharmasan.ventas.contrato-precio.editar',
            component: () => import('../views/contratos/pages/editar/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.ventas.contrato-precio.editar'],
              activeMenu: 'pharmasan.ventas.contrato-precio.listado'
            }
          },
          {
            path: 'ver/:id',
            name: 'pharmasan.ventas.contrato-precio.ver',
            component: () => import('../views/contratos/pages/ver/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.ventas.contrato-precio.ver'],
              activeMenu: 'pharmasan.ventas.contrato-precio.listado'
            }
          },
          {
            path: 'solicitudes',
            name: 'pharmasan.ventas.contrato-precio.solicitudes',
            component: () => import('../views/contratos/pages/solicitudes/index.vue'),
            redirect: { name: 'pharmasan.ventas.contrato-precio.solicitudes.listado' },
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.ventas.contrato-precio.solicitudes']
            },
            children: [
              {
                path: '',
                name: 'pharmasan.ventas.contrato-precio.solicitudes.listado',
                component: () => import('../views/contratos/pages/solicitudes/listado/index.vue'),
                beforeEnter: [securityAccess],
                meta: {
                  access: ['pharmasan.ventas.contrato-precio.solicitudes'],
                  activeMenu: 'pharmasan.ventas.contrato-precio.solicitudes'
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.ventas.contrato-precio.solicitudes.crear',
                component: () => import('../views/contratos/pages/solicitudes/crear/index.vue'),
                beforeEnter: [securityAccess],
                meta: {
                  access: ['pharmasan.ventas.contrato-precio.solicitudes.crear'],
                  activeMenu: 'pharmasan.ventas.contrato-precio.solicitudes'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'lista-precio',
        name: 'pharmasan.ventas.lista-precio',
        component: () => import('../views/listaPrecio/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'pharmasan.ventas.lista-precio'
          ]
        }
      }
    ]
  }
]
