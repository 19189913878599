import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/gestion/calidad-pharmasan',
    name: 'pharmasan.gestion.calidad.pharmasan',
    component: Layouts,
    redirect: { name: 'pharmasan.gestion.calidad.mapa-procesos-phar' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'mapa',
        name: 'pharmasan.gestion.calidad.mapa-procesos-phar',
        component: () => import('./views/mapa/Main').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-phar'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'ver',
        redirect: { name: 'pharmasan.gestion.calidad.mapa-procesos-phar' }
      },
      {
        path: 'ver-pharmasan/:id',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.ver',
        component: () => import('./views/mapa/Ver').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.ver'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'historial/:id',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.historial',
        component: () => import('./views/mapa/Historial').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.historial'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'inactivos',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.inactivos',
        component: () => import('./views/mapa/Inactivos').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.inactivos'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'crearFormato',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.crearFormato',
        component: () => import('./views/mapa/crearformato').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'administrar',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.administrar',
        component: () => import('./views/mapa/Administrar').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.administrar'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'codigos',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.codigos',
        component: () => import('./views/mapa/Codigos').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.codigos'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'solicitudes',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.solicitudes',
        component: () => import('./views/mapa/Solicitudes.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.solicitudes'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'solicitudes/crear',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.crear-solicitudes',
        component: () => import('./views/mapa/components/crearSolicitud.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.crear-solicitudes'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      },
      {
        path: 'solicitudes-phmasan/editar/:id',
        name: 'pharmasan.gestion.calidad.mapa-procesos-pharmasan.editar-solicitudes',
        component: () => import('./views/mapa/components/editarSolicitudes.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos-pharmasan.editar-solicitudes'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos-phar'
        }
      }
    ]
  }
]
