import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../../middleware/securityAccess'

export default [
  {
    path: '/pharmasan/ventas/facturacion/capita-valor',
    component: Layouts,
    redirect: { name: 'pharmasan.ventas.facturacion.capita-valor.listado' },
    name: 'pharmasan.ventas.facturacion.capita-valor',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['pharmasan.ventas.facturacion.capita-valor']
    },
    children: [
      {
        path: 'listado',
        name: 'pharmasan.ventas.facturacion.capita-valor.listado',
        component: () => import('../views/list/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Facturación Capita - Listado',
          activeMenu: 'pharmasan.ventas.facturacion.capita-valor.listado',
          access: ['pharmasan.ventas.facturacion.capita-valor.listado']
        }
      },
      {
        path: 'carga-ov-factura',
        redirect: { name: 'pharmasan.ventas.facturacion.capita-valor.listado' }
      },
      {
        path: 'carga-ov-factura/:DocNum',
        name: 'pharmasan.ventas.facturacion.capita-valor.carga-ov-factura',
        component: () => import('../views/cargaOvFactura/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Facturación Capita - Carga OV Factura',
          activeMenu: 'pharmasan.ventas.facturacion.capita-valor.listado',
          access: ['pharmasan.ventas.facturacion.capita-valor.carga-ov-factura']
        }
      },
      {
        path: 'crear',
        name: 'pharmasan.ventas.facturacion.capita-valor.crear',
        component: () => import('../views/form/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Facturación Capita - Crear',
          activeMenu: 'pharmasan.ventas.facturacion.capita-valor.crear',
          access: ['pharmasan.ventas.facturacion.capita-valor.crear']
        }
      },
      {
        path: 'listado-ovs',
        redirect: { name: 'pharmasan.ventas.facturacion.capita-valor.listado-ovs' }
      },
      {
        path: 'listado-ovs/:DocNum?',
        name: 'pharmasan.ventas.facturacion.capita-valor.listado-ovs',
        component: () => import('../views/listOvs/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Facturación Capita - Listado OVs',
          activeMenu: 'pharmasan.ventas.facturacion.capita-valor.listado-ovs',
          access: ['pharmasan.ventas.facturacion.capita-valor.listado-ovs']
        }
      }
    ]
  }
]
