import axios from 'axios'
import { useStore } from '../store'
import router from '../router'
import { messageWarning, errorHtml, errorHtmlTabla } from './mensajes'
import { funcion } from '../utils/funciones'
import Swal from 'sweetalert2'

axios.interceptors.request.use((config) => {
  const store = useStore()
  const source = axios.CancelToken.source()

  config.cancelToken = source.token
  config.headers.Authorization = `Bearer ${store.getters['auth/getToken']}`

  const { loading } = config.headers
  if (loading) document.body.classList.add('loading-indicator')
  store.commit('auth/pushCancelTokens', source)

  if (config.loading) funcion.cargando()

  return config
})

axios.interceptors.response.use((response) => {
  document.body.classList.remove('loading-indicator')
  funcion.cargando(0)
  return response
}, (err) => {
  document.body.classList.remove('loading-indicator')
  funcion.cargando(0)
  const store = useStore()
  if (err.response) {
    const { status, data } = err.response
    if (data.erroresPgp) {
      return data
    }
    if (status === 400 || status === 404) {
      const messages = []
      if (Array.isArray(data.message)) {
        Object.values(data.message).forEach(msg => {
          if (typeof msg === 'object') {
            msg.forEach(m => {
              messages.push(m)
            })
          } else {
            messages.push(msg)
          }
        })
        errorHtml('Error', messages.join('<br>'))
      } else if (typeof data.message === 'string' && !data.details) {
        console.log('Entre aqui 11')
        messageWarning(data.message)
      } else if (data.details) {
        console.log('Entre aqui 22', typeof data.details)
        if (typeof data.details === 'string') {
          Swal.fire({
            title: 'Advertencia',
            html: `${data.message} <br> <span style="font-size: 12px;">${data.details}</span>`,
            icon: 'warning'
          })
        } else {
          data.details.forEach(e => {
            messages.push(`<span style="font-size: 12px">${e.message}</span>`)
          })
          console.log('Pase por aqui')
          Swal.fire({
            title: 'Advertencia',
            html: `${data.message} <br>${messages.join('<br>')}`,
            icon: 'warning'
          })
        }
      } else {
        for (const key in data.message) {
          messages.push(JSON.stringify(data.message[key]))
        }
        errorHtml('Error', messages.join('<br>'))
      }
      return data
    } else if (status === 401) {
      store.commit('auth/LOGOUT')
      router.push({ name: 'login' })
    } else if (status === 403) {
      messageWarning('Necesitas permisos para realizar esta operacion')
    } else if (status === 500) {
      console.log('Http Error 500', err.response)
      if (!data.details) {
        messageWarning(data.message)
      } else {
        Swal.fire({
          title: 'Error',
          html: `${data.message} <br> <span style="font-size: 12px;">${data.details}</span>`,
          icon: 'error'
        })
      }
    } else if (status === 452) { // Creado por Oscar Chaparro
      errorHtmlTabla(data)
    } else {
      errorHtml('Http Error status desconocido', data)
    }
  } else {
    console.warn('Http Error desconocido', err)
  }

  // return err
  return Promise.reject(err)
})

export default axios
