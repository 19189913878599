import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
// import securityAccess from '@/middleware/securityAccess'

export default [
  {
    path: '/pharmasan/ventas',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'rips',
        name: 'pharmasan.ventas.rips',
        redirect: { name: 'pharmasan.ventas.rips.listado' },
        children: [
          {
            path: 'listado',
            name: 'pharmasan.ventas.rips.listado',
            component: () => import('../views/list/index.vue'),
            meta: {
              title: 'RIPS - Listado',
              activeMenu: 'pharmasan.ventas.rips.listado',
              access: [
                'pharmasan.ventas.rips.listado'
              ]
            }
          }
        ]
      }
    ]
  }
]
