const menuCollapse = (route) => {
  const menu = document.getElementsByClassName('side-nav')
  const namePage = document.getElementsByClassName('name-page')

  // Definir un conjunto de rutas permitidas
  const allowedRoutes = [
    'pharmasan.compras.logistica.cargue-facturas.xml',
    'pharmasan.compras.logistica.cargue-facturas.editar',
    'daily-tech.inventario-ti',
    'daily-tech.inventario-ti.listado',
    'pharmasan.ventas.facturacion.capita-valor.listado',
    'pharmasan.ventas.facturacion.capita-valor.crear'
  ]

  setTimeout(() => {
    if (menu.length > 0 && namePage.length > 0) {
      if (!allowedRoutes.includes(route.name)) {
        // Si la ruta no está definida, quitar las clases
        menu[0].classList.remove('side-nav--simple')
        namePage[0].classList.remove('invisible')
      } else if (allowedRoutes.includes(route.name)) {
        // Si la ruta está en el conjunto de rutas permitidas, agregar las clases
        menu[0].classList.add('side-nav--simple')
        namePage[0].classList.add('invisible')
      }
    }
  }, 500)
}

module.exports = {
  menuCollapse
}
