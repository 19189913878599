import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/gestion/calidad',
    name: 'pharmasan.gestion.calidad',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.gestion.calidad.mapa-procesos' },
    children: [
      {
        path: 'mapa',
        name: 'pharmasan.gestion.calidad.mapa-procesos',
        component: () => import('./views/mapa/Main').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'ver',
        redirect: { name: 'pharmasan.gestion.calidad.mapa-procesos' }
      },
      {
        path: 'ver/:id',
        name: 'pharmasan.gestion.calidad.mapa-procesos.ver',
        component: () => import('./views/mapa/Ver').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.ver'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'historial/:id',
        name: 'pharmasan.gestion.calidad.mapa-procesos.historial',
        component: () => import('./views/mapa/Historial').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.historial'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'inactivos',
        name: 'pharmasan.gestion.calidad.mapa-procesos.inactivos',
        component: () => import('./views/mapa/Inactivos').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.inactivos'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'crearFormato',
        name: 'pharmasan.gestion.calidad.mapa-procesos.crearFormato',
        component: () => import('./views/mapa/crearformato').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'administrar',
        name: 'pharmasan.gestion.calidad.mapa-procesos.administrar',
        component: () => import('./views/mapa/Administrar').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.administrar'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'codigos',
        name: 'pharmasan.gestion.calidad.mapa-procesos.codigos',
        component: () => import('./views/mapa/Codigos').then(m => m || m),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.codigos'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'solicitudes',
        name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes',
        component: () => import('./views/mapa/Solicitudes.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.solicitudes'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'solicitudes/crear',
        name: 'pharmasan.gestion.calidad.mapa-procesos.crear-solicitudes',
        component: () => import('./views/mapa/components/crearSolicitud.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.crear-solicitudes'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      },
      {
        path: 'solicitudes/editar/:id',
        name: 'pharmasan.gestion.calidad.mapa-procesos.editar-solicitudes',
        component: () => import('./views/mapa/components/editarSolicitudes.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.gestion.calidad.mapa-procesos.editar-solicitudes'],
          activeMenu: 'pharmasan.gestion.calidad.mapa-procesos'
        }
      }
    ]
  }
]
