import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../middleware/securityAccess'

export default [
  {
    path: '/dailytech/inventario-ti',
    component: Layouts,
    redirect: { name: 'daily-tech.inventario-ti.listado' },
    name: 'daily-tech.inventario-ti',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['daily-tech.inventario-ti']
    },
    children: [
      {
        path: 'listado',
        name: 'daily-tech.inventario-ti.listado',
        component: () => import('../views/list/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Inventario TI - Listado',
          activeMenu: 'daily-tech.inventario-ti.listado',
          access: ['daily-tech.inventario-ti.listado']
        }
      },
      {
        path: 'carga-masiva',
        name: 'daily-tech.inventario-ti.carga-masiva',
        component: () => import('../views/cargaMasiva/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Inventario TI - Carga Masiva',
          activeMenu: 'daily-tech.inventario-ti.listado',
          access: ['daily-tech.inventario-ti.carga-masiva']
        }
      },
      {
        path: 'crear',
        name: 'daily-tech.inventario-ti.crear',
        component: () => import('../views/form/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Inventario TI - Crear',
          activeMenu: 'daily-tech.inventario-ti.listado',
          access: ['daily-tech.inventario-ti.crear']
        }
      },
      {
        path: 'perfil',
        name: 'daily-tech.inventario-ti.perfil',
        redirect: { name: 'daily-tech.inventario-ti.listado' },
        beforeEnter: [securityAccess]
      },
      {
        path: 'perfil/:id',
        name: 'daily-tech.inventario-ti.perfil.id',
        redirect: { name: 'daily-tech.inventario-ti.perfil.id.details' },
        component: () => import('../views/profileInventory/home/index.vue'),
        beforeEnter: [securityAccess],
        meta: {
          title: 'Inventario TI - Perfil',
          activeMenu: 'daily-tech.inventario-ti.listado',
          access: ['daily-tech.inventario-ti.listado']
        },
        children: [
          {
            path: 'detalles',
            name: 'daily-tech.inventario-ti.perfil.id.details',
            component: () => import('../views/profileInventory/details/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Perfil - Detalles',
              activeMenu: 'daily-tech.inventario-ti.listado',
              access: ['daily-tech.inventario-ti.perfil.id.details']
            }
          },
          {
            path: 'actividad',
            name: 'daily-tech.inventario-ti.perfil.id.activity',
            component: () => import('../views/profileInventory/activity/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Perfil - Actividad',
              activeMenu: 'daily-tech.inventario-ti.listado',
              access: ['daily-tech.inventario-ti.perfil.id.activity']
            }
          }
        ]
      },
      {
        path: 'configuracion',
        name: 'daily-tech.inventario-ti.configuracion',
        component: () => import('../views/configuracion/index.vue'),
        beforeEnter: [securityAccess],
        redirect: { name: 'daily-tech.inventario-ti.configuracion.home' },
        meta: {
          title: 'Inventario TI - Configuración',
          activeMenu: 'daily-tech.inventario-ti.configuracion',
          access: ['daily-tech.inventario-ti.configuracion']
        },
        children: [
          {
            path: '',
            name: 'daily-tech.inventario-ti.configuracion.home',
            component: () => import('../views/configuracion/home/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración',
              activeMenu: 'daily-tech.inventario-ti.configuracion.home',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'dispositivos',
            name: 'daily-tech.inventario-ti.configuracion.dispositivos',
            component: () => import('../views/configuracion/dispositivos/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Dispositivos',
              activeMenu: 'daily-tech.inventario-ti.configuracion.dispositivos',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'marcas',
            name: 'daily-tech.inventario-ti.configuracion.marcas',
            component: () => import('../views/configuracion/marcas/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Marcas',
              activeMenu: 'daily-tech.inventario-ti.configuracion.marcas',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'modelos',
            name: 'daily-tech.inventario-ti.configuracion.modelos',
            component: () => import('../views/configuracion/modelos/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Modelos',
              activeMenu: 'daily-tech.inventario-ti.configuracion.modelos',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'ubicaciones',
            name: 'daily-tech.inventario-ti.configuracion.ubicaciones',
            component: () => import('../views/configuracion/ubicaciones/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Ubicaciones',
              activeMenu: 'daily-tech.inventario-ti.configuracion.ubicaciones',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'owners',
            name: 'daily-tech.inventario-ti.configuracion.owners',
            component: () => import('../views/configuracion/owners/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Dueños',
              activeMenu: 'daily-tech.inventario-ti.configuracion.owners',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'estados',
            name: 'daily-tech.inventario-ti.configuracion.estados',
            component: () => import('../views/configuracion/estados/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Estados',
              activeMenu: 'daily-tech.inventario-ti.configuracion.estados',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          },
          {
            path: 'campos',
            name: 'daily-tech.inventario-ti.configuracion.campos',
            component: () => import('../views/configuracion/campos/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              title: 'Inventario TI - Configuración - Campos',
              activeMenu: 'daily-tech.inventario-ti.configuracion.campos',
              access: ['daily-tech.inventario-ti.configuracion']
            }
          }
        ]
      }
    ]
  }
]
