import { createRouter, createWebHistory } from 'vue-router'
import VueRouterMultiguard from 'vue-router-multiguard'
import store from '../store'
import { locale } from '@/middleware'
import { menuCollapse } from '@/utils/collapseMenu'
import errorsRoutes from '../apps/system/errors/router'
// importando rutas de modulos
import routesSecurity from '@/apps/system/security/router'
import dashboardRoutes from '../apps/pharmasan/dashboard/router'
import estadosFinancieros from '../apps/pharmasan/estadosFinancieros/router'
import ajustesRoutes from '../apps/system/ajustes/router'
import commonRoutes from '../apps/system/common/router'
import adminRoutes from '../apps/pharmasan/comprasAdministrativas/router'
import menuMiddleware from '../middleware/menuMiddleware'
import dailytechDashboard from '../apps/dailyTech/dashboardDailytech/router'
import dashboardCapitalstate from '../apps/capitalState/dashboardCapitalstate/router'
import dashboardUTPharmedis from '../apps/utPharmedis/dashboard/router'
import dashboardUTEspecializada from '../apps/utEspecializada/dashboard/router'
import amortizations from '../apps/pharmasan/accounting/amortizations/router'
import calidad from '../apps/pharmasan/calidad/router'
import calidadPhar from '../apps/pharmasan/calidadPhar/router'
import sap from '../apps/pharmasan/moduloSap/consultasSap/router'
import investments from '../apps/capitalState/investments/router'
import investmentCapitalState from '../apps/capitalState/investment/router'
import constructionBudgetCapitalState from '../apps/capitalState/constructionBudget/router'
import pettycash from '../apps/pharmasan/pettyCash/router'
import licitaciones from '@/apps/pharmasan/licitaciones/router'
import facturacion from '@/apps/pharmasan/facturacion/router'
import facturacionUTSegupharma from '@/apps/utsegupharma/facturacion/router'
import pettycashNewgas from '@/apps/newGasAndOil/newGasPettyCash/router'
import procesosJuridicos from '../apps/pharmasan/juridica/procesosJuridicos/routes'
import defaultRoutes from '../apps/system/home/router'
import cartera from '@/apps/pharmasan/cartera/router'
import utPharmedicasEntradasMercanciaRoutes from '@/apps/utpharmedicas/entradaMercancia/router'
import utPharmedicasDashboard from '@/apps/utpharmedicas/dashboard/router'
import publicaciones from '../apps/pharmasan/general/router'
import farmacia from '../apps/pharmasan/farmacia/router'
import usuarios from '../apps/usuarios/routes/router'
import senspro from '../apps/pharmasan/senspro/router'
import comprasRoutes from '../apps/pharmasan/compras/router'
import asmetRouter from '../apps/pharmasan/compras/router_asmet'
import recursosHumanos from '../apps/pharmasan/recursosHumanos/routes/router.js'
import dailyTechEstadosFinancieros from '../apps/dailyTech/estadosFinancieros/router.js'
import capitalStateEstadosFinancieros from '../apps/capitalState/estadosFinancieros/router.js'
import sapOv from '../apps/pharmasan/sap/router/index.js'
import solicitudPermisos from '../apps/pharmasan/administrativa/solicitud-permisos/router/index.js'
import ventasUtSeguPharma from '../apps/utsegupharma/ventas/router'
import logistica from '../apps/pharmasan/logistica/V1/router/index.js'
import informes from '../apps/pharmasan/informes/routes.js'
import rips from '../apps/pharmasan/ventas/rips/routes/index.js'
import inventarioTi from '../apps/dailyTech/inventario-ti/router/index.js'
import facturasCapitaValor from '../apps/pharmasan/facturacion/capita/router/index.js'

const routes = [
  ...routesSecurity,
  ...dashboardRoutes,
  ...estadosFinancieros,
  ...ajustesRoutes,
  ...commonRoutes,
  ...adminRoutes,
  ...dailytechDashboard,
  ...dashboardCapitalstate,
  ...dashboardUTPharmedis,
  ...dashboardUTEspecializada,
  ...amortizations,
  ...errorsRoutes,
  ...defaultRoutes,
  ...calidad,
  ...calidadPhar,
  ...sap,
  ...investments,
  ...investmentCapitalState,
  ...constructionBudgetCapitalState,
  ...pettycash,
  ...licitaciones,
  ...facturacion,
  ...facturacionUTSegupharma,
  ...pettycashNewgas,
  ...procesosJuridicos,
  ...cartera,
  ...utPharmedicasEntradasMercanciaRoutes,
  ...utPharmedicasDashboard,
  ...publicaciones,
  ...farmacia,
  ...usuarios,
  ...senspro,
  ...comprasRoutes,
  ...recursosHumanos,
  ...dailyTechEstadosFinancieros,
  ...capitalStateEstadosFinancieros,
  ...sapOv,
  ...asmetRouter,
  ...solicitudPermisos,
  ...ventasUtSeguPharma,
  ...logistica,
  ...informes,
  ...rips,
  ...inventarioTi,
  ...facturasCapitaValor
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})
router.beforeEach(VueRouterMultiguard([locale, menuMiddleware]))

router.beforeEach((to, from, next) => {
  // console.log('paso por aqui')
  // to.name === 'pharmasan.sap.ov.crear' ? store.commit('main/setOv', true) : store.commit('main/setOv', false)
  menuCollapse(to)
  store.dispatch('auth/executeCancelTokens')
  next()
})

export default router
